@mixin force_nomargin {
  margin: 0px !important;
}

%force_nomargin {
  @include force_nomargin;
}

.group, .rule {
}

.rule {
  padding: 5px;
}

.group {
  padding-left: 0px;
}
.rule_group {
  padding-left: 5px;
}

.group--children {
  @extend %force_nomargin;
  padding-left: 10px !important;

  & > .group-or-rule-container > .group-or-rule {
    &::before, &::after {
        left: -7px;
        width: 7px;
    }
  }
  
  & > .group-or-rule-container:first-child > .group-or-rule {
      &::before {
        top: -7px;
      }
  }
}


.group--conjunctions {
  @extend %force_nomargin;
  margin-left: 4px !important;
}

.group-or-rule-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 5px;
}
.group--children {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.group--header,
.group--footer {
  margin: {
    top: 5px !important;
    bottom: 5px !important;
  }
}

.rule--operator, .widget--widget, .widget--valuesrc, .widget--sep {
  margin-left: 5px;
}

.rule_group {
  .group--actions {
    margin-left: 5px;
  }
  .rule_group--children {
    padding-left: 10px !important;
    &.one--child {
      padding-left: 5px !important;
    }

    & > .group-or-rule-container > .group-or-rule {
      &::before, &::after {
          left: -7px;
          width: 7px;
      }
    }
  }
}

.widget--valuesrc {
  margin-right: -3px;
}
.operator--options--sep {
  margin-right: 5px;
}
.group--header {
  padding: {
    left: 0;
    right: 5px;
  }
  margin: 0;
}

.group--drag-handler {
  margin-left: 5px;
}

.rule--func--arg-value > .rule--widget {
  margin-left: -5px;
}
