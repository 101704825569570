@font-face {
  font-family: 'Gotham Book';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Book'), url('https://fonts.cdnfonts.com/s/13925/GOTHAMBOOK.woff') format('woff');
}

body {
  margin: 0;
  min-width: 320px;
  height: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#bbba ;
}



.webmap {
  width: auto;
  height: 50vh;
}

